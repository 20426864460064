import { React } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { BiLinkExternal } from "react-icons/bi";

function ProjectCards(props) {
    return(
        <Card className="project-card-view">

            <Card.Body>
                <Card.Title>{props.title}</Card.Title>
                <Card.Text style={{ textAlign: "center" }}>{props.description}</Card.Text>
                <Button variant="primary" href={props.link} target="_blank">
                    <BiLinkExternal /> &nbsp;
                    {"Ver Projeto"}
                </Button>
            </Card.Body>

        </Card>
    );
}

export default ProjectCards;
