import React from "react";
import Card from "react-bootstrap/Card";
import { Container, Row, Col } from "react-bootstrap";
import { AiFillGithub } from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";
import { ImPointRight } from "react-icons/im";
import workoutPng from "../../Assets/about.svg";

function AboutCard() {
  return (

    <Container>
      <Row>

        <Col
          md={5}
          style={{ paddingTop: "20px", paddingBottom: "50px" }}
          className="about-img"
        >
          <img src={workoutPng} alt="workout" className="img-fluid" />
        </Col>

        <Col>

          <Card className="quote-card-view">
            <Card.Body>
              <blockquote className="blockquote mb-0">
                <p style={{ textAlign: "justify" }}>
                    Além da codificação, existem outras atividades que adoro fazer!
                </p>
                <ul>
                  <li className="about-activity">
                    <ImPointRight /> Viajar
                  </li>
                  <li className="about-activity">
                    <ImPointRight /> Ler
                  </li>
                  <li className="about-activity">
                    <ImPointRight /> Assistir filmes e séries
                  </li>
                  <li className="about-activity">
                    <ImPointRight /> Conhecer novas culturas
                  </li>
                  <li className="about-activity">
                    <ImPointRight /> Estudar astronomia
                  </li>
                  <li className="about-activity">
                    <ImPointRight /> Ouvir músicas
                  </li>
                </ul>
              </blockquote>

            </Card.Body>
          </Card>

        </Col>


      </Row>

      <Row>
        <Col md={12} className="home-about-social">
          <h1>ENCONTRE-ME EM</h1>
          <ul className="home-about-social-links">
            <li className="social-icons">
              <a
                href="https://github.com/Matheus-Fontinele-Alves-Vieria"
                aria-label="Github"
                target="_blank"
                rel="noreferrer"
                className="icon-colour  home-social-icons"
              >
                <AiFillGithub />
              </a>
            </li>
            <li className="social-icons">
              <a
                href="https://www.linkedin.com/in/matheus-vieira-936bbb162/"
                target="_blank"
                aria-label="LinkedIn"
                rel="noreferrer"
                className="icon-colour  home-social-icons"
              >
                <FaLinkedinIn />
              </a>
            </li>
          </ul>
          <p>
            Sinta-se à <span className="purple">vontade para entrar em contato </span>comigo
          </p>
        </Col>
      </Row>
    </Container>

  );
}

export default AboutCard;
