import { React } from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCards from "./ProjectCards";

function Projects() {
    return(
        <Container fluid id="project" className="project-section">
            <Container>
                <h1 className="project-heading">
                    <span className="purple">Projetos</span> Recentes
                </h1>
                <p style={{ color: "white" }}>
                    Aqui estão alguns projetos em que trabalhei recentemente.
                </p>
                <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>

                    <Col md={4} className="project-card">
                        <ProjectCards 
                            title="Flower Classification"
                            description="Modelo de IA para classificar diferentes tipos de flores 
                            com base em suas características, como comprimento das pétalas e 
                            largura das sépalas. Uso o algoritmo máquinas de vetores de suporte 
                            (SVM), para treinar o modelo."
                            link="https://colab.research.google.com/drive/1nHyMySfRSgKnZ_PuhP-cdOrf4MmLf8tP?usp=sharing"
                        />
                    </Col>

                    <Col md={4} className="project-card">
                        <ProjectCards 
                            title="Reinforcement Learning"
                            description="Modelo de aprendizado por reforço usando um ambiente Gymnasium."
                            link="https://colab.research.google.com/drive/1GJMCh38HEA8bDENlCdkHQVthwVWCqgY8#scrollTo=BEvG-F1v_xjw"
                        />
                    </Col>

                    <Col md={4} className="project-card">
                        <ProjectCards 
                            title="Artificial Intelligence Project"
                            description="Projeto da disciplina de Inteligência Artificial da faculdade."
                            link="https://github.com/Matheus-Fontinele-Alves-Vieria/Artificial-Intelligence"
                        />
                    </Col>

                    <Col md={4} className="project-card">
                        <ProjectCards 
                            title="NLP Project"
                            description="Projeto usundo o NLP para análise de sentimentos em comentários 
                            sobre filmes no site IMDB."
                            link="https://colab.research.google.com/drive/1q3EAjYQBiyq3TKpv7EeF4Roy-0QO4m8K"
                        />
                    </Col>

                    <Col md={4} className="project-card">
                        <ProjectCards 
                            title="Routes in the City of Fortaleza"
                            description="Traçar rotas na cidade de Fortaleza usando GeoPandas, Folium e openrouteservice API
                            e algoritmos de heurísticos para selecionar as melhores possíveis."
                            link="https://colab.research.google.com/drive/1q4AGivUdo6pabacBYMAcLUtstiVuPDbY#scrollTo=bWanaAYQrHNF"
                        />
                    </Col>

                </Row>
            </Container>
        </Container>
    );
}

export default Projects;
