import { React } from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.svg";
import AboutCard from "./AboutCard";
import Particle from "../Particle";

function About() {
    return(
        <Container fluid className="home-about-section" id="about">
            <Particle />
            <Container>
                <Row>
                    <Col md={8} className="home-about-description">
                        <h1 style={{ fontSize: "2.6em" }}>
                            UMA BREVE <span className="purple"> APRESENTAÇÃO </span> SOBRE MIM
                        </h1>
                        <p className="home-about-body">
                            Em meu último estágio, trabalhei como desenvolvedor Python e tive a oportunidade de desenvolver habilidades em 
                            inteligência artificial, bem como, as bibliotecas e frameworks Pandas, Numpy, TensorFlow, scikit-learn e metodologias 
                            ágeis, como o SCRUM. 
                            <br />
                            <br />
                            Tenho trabalhado em projetos de desenvolvimento, tais como, protótipos de sistemas para monitoramento da temperatura 
                            da compostagem, um aplicativo de astronomia para educação básica, um protótipo de sistema para prestação de contas 
                            e, de acordo do que foi citado acima, como desenvolvedor Python,  tive a participação no desenvolvimento de um 
                            modelo de inteligência artificial para customização de atendimento ao cliente. Todas essas experiências me permitiu 
                            aplicar os meus conhecimentos e também aprender muito mais, além de colaborar com equipes multidisciplinares para 
                            atingir objetivos comuns.
                        </p>
                    </Col>
                    <Col md={4} className="myAvtar">
                        <img src={myImg} className="img-fluid avatar-img" alt="avatar" />
                    </Col>
                </Row>

                <AboutCard />

            </Container>
        </Container>
    );
}

export default About;
