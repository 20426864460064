import { React } from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.svg";
import Tilt from "react-parallax-tilt";
//import { AiFillGithub } from "react-icons/ai";
//import { FaLinkedinIn } from "react-icons/fa";

function Home2() {
    return(
        <Container fluid className="home-about-section" id="about">
            <Container>
                <Row>
                    <Col md={8} className="home-about-description">
                        <h1 style={{ fontSize: "2.6em" }}>
                            VOU ME APRESENTAR
                        </h1>
                        <p className="home-about-body">
                            Sou apaixonado por ciência e tecnologia, e gosto sempre de
                            aprender e explorar novos horizontes. Eu sempre busco o
                            melhor para o meu trabalho e sempre busco o melhor para o 
                            mundo.
                            <br/>
                            <br/>
                            Tenho conhecimentos nas seguintes tecnologias
                            <i>
                                <b className="purple">
                                    {" "}
                                    Python e JavaScript.
                                </b>
                            </i>
                            <br/>
                            <br/>
                            Minha área de interesse está relacionada a &nbsp; 
                            <i>
                                <b className="purple">
                                    Ciência de Dados, tal como, desenvolver algoritmos de Inteligência Artificial.
                                </b>
                            </i>
                            <br/>
                            <br/>
                            Além disso, também busco aplicar e melhorar
                            meus conhecimentos nos frameworks
                            <i> 
                                <b className="purple">
                                    {" "}    
                                    Flask, Django e NextJS
                                </b>
                            </i> 
                            &nbsp; para desenvolver meus produtos.
                        </p>
                    </Col>
                    <Col md={4} className="myAvtar">
                        <Tilt>
                            <img
                                src={myImg}
                                className="img-fluid"
                                alt="avatar"
                            />
                        </Tilt>
                    </Col>
                </Row>
            </Container>
        </Container>
    );
}

export default Home2;
